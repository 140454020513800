.header {
    @apply flex sticky max-w-full z-50 items-center;
    @apply bg-white;
    @apply pt-2 pb-2 xl:pl-4 xl:pr-4 lg:pl-4 lg:pr-4 md:pl-4 md:pr-4 sm:pl-4 sm:pr-4 pl-4 pr-4;
    height: 60px;
    box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent,0 0 transparent, 0 2px 6px -1px rgba(0,0,0,0.1),0 2px 2px -1px rgba(0,0,0,0.06);;

}

.logo {
    width: 162px;
    height: 37px;
}

.initialsCircle {
    margin-left: auto;
    text-align: center;
    cursor: pointer;
    font-size: 100%;
    width: 2.8em;
    height: 2.8em;
    line-height: 2.8em;
    border-radius: 50%;
    background: white;
    vertical-align: middle;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.6);
}

