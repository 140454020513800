.loginWrapper {
    height: 100vh;
    background-color: #455c74;
    @apply items-center flex justify-center;
}

.loginCard {
    @apply max-w-full xl:h-40 lg:h-40 md:h-40 h-48 rounded-lg bg-white;
    @apply flex flex-col text-center;
    @apply shadow-md pl-4 pr-4;
    padding-top: 1.5em;
}

.login-info {
    font-size: 1.2em;
}

.loginButton {
    @apply flex justify-center;
    margin: 1.5em 0;
    cursor: pointer;
}

@media (max-width: 769px) {
    .login-card {
        width: 95%;
    }
}
