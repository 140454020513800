
.user-detail {
    margin-top: 50px;
    margin-right: 15px;
    z-index: 1000;
    width: 100%;
    min-width: 200px;
    max-width: 200px;
    height: 100%;
    min-height: 170px;
    max-height: 170px;
    background: white 0 0 no-repeat padding-box;
    border-radius: 3%;
    opacity: 1;
    position: absolute;
    right: 1%;
    padding-bottom: 1.5rem;
    box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 2px 12px -1px rgb(0 0 0 / 10%), 0 -6px 21px 3px rgb(0 0 0 / 6%);
}

.initialsCircle {
    text-align: center;
    cursor: pointer;
    font-size: 100%;
    width: 3em;
    height: 3em;
    line-height: 3em;
    border-radius: 50%;
    background: white;
    vertical-align: middle;
    color: black;
    margin: 0 auto 1em;
    border: 1px solid black;
}

.userFullName {
    text-align: center;
    font: normal normal normal 18px/24px Roboto;
    letter-spacing: 0.16px;
    color: rgba(0, 0, 0, 0.6);
    opacity: 1;
    margin: auto;
    padding-top: 1em;
}

.logoutButton {
    text-align: center;
    background: white 0 0% no-repeat padding-box;
    font: normal normal normal 14px/20px Roboto;
    letter-spacing: 2px;
    border-radius: 4px;
    border: 1px solid #58a8ff;
    color: #58a8ff;
    cursor: pointer;
    padding: 2% 7% 2% 7%;
    margin-top: 2em;
}

.popup {
    position: absolute;
    top: 4em;
    height: 200px;
    width: 200px;
    border-radius: 8px;
    right: 3em;
    z-index: 2;
    background-color: white;
    padding: 1em;
    box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent,0 0 transparent, 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);;
}
