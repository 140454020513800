@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,500;1,700;1,900&display=swap');

html, body, #root {
  font-family: Roboto, serif;
  -webkit-font-smoothing: antialiased;
}

html {
  height: 100%;
}

#root {
  height: 100vh;
}

body {
  height: 100vh;
  padding: 0;
}

.App {
  @apply flex;
  text-align: center;
  height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  flex-direction: column;
  justify-content: space-between;
  background-color: #faf9f8;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

